/**
 * Use the regular promoted compact cards for tertiary content that warrants a lower level of visual prominence, or to surface related content and cross-links within pages
 *
 * @module views/components/RegularPromotedCompactCards
 * @memberof -Common
 */
import './RegularPromotedCompactCards.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import ColorCard from '@ulta/components/ColorCard/ColorCard';

import { constants } from '@ulta/utils/constants/constants';

/**
 * Represents a RegularPromotedCompactCards  component
 *
 * @method
 * @param {RegularPromotedCompactCardsProps} props - React properties passed from composition
 * @returns RegularPromotedCompactCards
 */
export const RegularPromotedCompactCards = React.forwardRef( ( props, _ ) => {
  const cardsLength = props.cards?.length;
  const regularPromotedCompactCardsRef = useRef( null );
  const [currentRegularPromotedCompactCardsRef, setRegularPromotedCompactCardsRef] = useState( '' );
  const { rootMargin, root, threshold } = props;

  const isIntersecting = useIntersectionObserver( regularPromotedCompactCardsRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  useEffect( () => {
    // set regularPromotedCompactCardsRef
    if( regularPromotedCompactCardsRef.current ){
      setRegularPromotedCompactCardsRef( regularPromotedCompactCardsRef.current );
    }
  }, [] );

  return (
    <div className='RegularPromotedCompactCards'
      ref={ regularPromotedCompactCardsRef }
    >
      <div className='RegularPromotedCompactCards__wrapper'>
        <div className='RegularPromotedCompactCards__content'>
          {
            cardsLength === 3 &&
            <div className='RegularPromotedCompactCards__heading'>
              {
                props.sectionTitle &&
                <div className='RegularPromotedCompactCards__headingTitle'>
                  <Text
                    textAlign='left'
                    textStyle='title-6'
                    htmlTag='h2'
                  >
                    { props.sectionTitle }
                  </Text>
                </div>
              }
              {
                props.sectionSubTitle &&
                <div className='RegularPromotedCompactCards__headingSubtitle'>
                  <Text
                    textAlign='left'
                    textStyle='body-2'
                  >
                    { props.sectionSubTitle }
                  </Text>
                </div>
              }
            </div>
          }
          <div className={
            classNames( 'RegularPromotedCompactCards__cards', {
              [`RegularPromotedCompactCards--oneItem`]: cardsLength === 1,
              [`RegularPromotedCompactCards--twoItems`]: cardsLength === 2,
              [`RegularPromotedCompactCards--threeItems`]: cardsLength === 3
            } )
          }

          >
            {
              props.cards.map( ( item, index ) => {
                let headline = item.headlineText?.text ? item.headlineText : null;
                if( !headline ){
                  headline = {
                    htmlTag: 'p',
                    textStyle:'body-1',
                    textAlign: 'left',
                    text: item.headline
                  };
                }
                return (
                  <div className='RegularPromotedCompactCards__Item'
                    key={ index }
                  >
                    <ColorCard
                      image={ item.image }
                      headline={ headline }
                      subheadline={ item.subheadline }
                      linkAction={ item.linkAction }
                    />
                  </div>
                );
              }
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
} );

/**
  * Property type definitions
  * @typedef RegularPromotedCompactCardsProps
  * @type {object}
  * @property {string} sectionTitle - Sets the section title properties
  * @property {string} sectionSubTitle - Sets the section subtitle properties
  * @property {array} cards - Sets the compact mini item content
  */
export const propTypes = {
  sectionTitle: PropTypes.string,
  sectionSubTitle: PropTypes.string,
  cards: PropTypes.array
};

export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

RegularPromotedCompactCards.defaultProps = defaultProps;
RegularPromotedCompactCards.propTypes = propTypes;
RegularPromotedCompactCards.displayName = 'RegularPromotedCompactCards';

export default RegularPromotedCompactCards;
