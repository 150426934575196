/**
 * Represents a color card component to create card
 *
 * @module views/components/ColorCard
 * @memberof -Common
 */
import './ColorCard.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

/**
 * Represents a ColorCard component
 *
 * @method
 * @param {ColorCardProps} props - React properties passed from composition
 * @returns ColorCard
 */
export const ColorCard = function( props ){
  const {
    linkAction,
    headline,
    subheadline,
    image
  } = props;
  const ariaLabelHeadline = `${linkAction?.label} ${( headline?.text || subheadline )}`;
  return (
    <div className='ColorCard'>
      <div className='ColorCard__content'>
        { image &&
          <div className='ColorCard__leftColumn'>
            <UltaAsset
              { ...( image ) }
              shouldUseIntersectionObserver={ false }
            />
          </div>
        }
        <div className='ColorCard__rightColumn'>
          {
            headline?.text &&
            <div className='ColorCard__title'>
              <Text
                htmlTag={ headline.htmlTag }
                textStyle={ headline.textStyle }
                textAlign={ headline.textAlign }
              >
                { headline.text }
              </Text>
            </div>
          }
          {
            subheadline &&
            <div className='ColorCard__subtitle'>
              <Text
                htmlTag='p'
                textStyle='body-2'
                textAlign='left'
                color='neutral-800'
              >
                { subheadline }
              </Text>
            </div>
          }
          {
            linkAction?.label &&
            <div className='ColorCard__action'>
              {
                linkAction.clientActionType &&
                <Button
                  action={ linkAction }
                  label={ linkAction.label }
                  variant='link'
                />
              }
              {
                !linkAction.clientActionType &&
                <Link_Huge
                  compact
                  action={ linkAction }
                  withHover
                  ariaLabel={ ariaLabelHeadline }
                >
                  { linkAction.label }
                </Link_Huge>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ColorCardProps
 * @type {object}
 * @property {object} image - Set the image to display
 * @property {object} headline - Sets the headline properties
 * @property {string} subheadline - Sets the subheadline
 * @property {object} linkAction - Sets the linkAction properties
 */
export const propTypes = {
  image: PropTypes.object,
  headline: PropTypes.oneOfType( [PropTypes.string, PropTypes.object] ),
  subheadline: PropTypes.string,
  linkAction: PropTypes.object
};

/**
 * Default values for passed properties
 * @typedef TextProps
 * @type {object}
 * @property {string} textStyle - The default class of the palette design display configuration
 * @property {string} htmlTag - The default HTML tag for the container
 * @property {string} textAlign - The default value for text alignment
 */
export const defaultProps = {
  headline :{
    textStyle: 'body-1',
    htmlTag: 'p',
    textAlign: 'left'
  }
};

ColorCard.propTypes = propTypes;
ColorCard.defaultProps = defaultProps;

export default ColorCard;
